$body: white;
$nav: #222;
$margin: 0%;
$padding: 20%;

body {
  display: flex;
  flex-direction: column;
  background-color: $body;
  color: if(lightness($body) > 50%, black, white);
  /* font-size: calc(.6vw + .8em) */
  margin: 0 $margin;
  min-height: 100vh; }

h1 {
  text-align: left; }

h1, h2 {
  margin: 0.4em 0;

  letter-spacing: 4px;
  text-transform: uppercase; }


nav {
  margin: 1em 0;
  li {
    display: inline-block;
    list-style-type: none;
    margin-right: 2em;
    a {
      font: inherit; } } }

article {
  border-top: 1px solid rgba(140, 140, 140, .1);
  header {
    text-align: center;
    span {
      color: gray; } } }

//  footer
//    margin: 2em 0

table {
  width: 100%; }

footer a {
  font-weight: 300; }

article, body > header, body > footer {
  padding: 1em $padding; }

body > header, body > footer {
  background-color: $nav;
  color: if(lightness($nav) > 50%, black, white); }

body > footer {
  margin-top: auto;
  justify-content: space-between; }

//  display: flex

.ghost {
  padding: .4em .8em; }

.fa {
  margin: 0 0.25em; }
